<template>
  <a-drawer
      title="店铺基本信息"
      placement="right"
      :closable="true"
      :visible="showDrawer"
      width="1100"
      @close="onClose"
  >
    <a-descriptions bordered>
      <a-descriptions-item label="店铺名称">
        <span class="font-bold">{{ baseInfo.shopName }}</span>
        <a-button
            class="ml-20"
            size="small"
            type="primary"
            @click="handleShowEditAddress"
        ><a-icon type="edit"/>编辑</a-button>
      </a-descriptions-item>
      <a-descriptions-item label="昵称">
        <span class="font-bold">{{ baseInfo.userName }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="用户ID">
        <span
            class="font-bold color-blue text-underline text-cursor"
            @click="handleGoToUserInfo(baseInfo.userId)"
        >{{ baseInfo.userId }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="店铺介绍" :span="3">
        <span class="font-bold">{{ baseInfo.shopInfo }}</span>
        <a-button
            class="ml-20"
            size="small"
            type="primary"
            @click="handleShowEditAddress"
        ><a-icon type="edit"/>编辑</a-button>
      </a-descriptions-item>
      <a-descriptions-item label="其它平台店铺名称">
        <span class="font-bold">{{ baseInfo.otherShopName }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="设置店铺星级" :span="2">
        <a-rate :defaultValue="baseInfo.star" v-model="starValue" :count="6" disabled :tooltips="starDesc" />
      </a-descriptions-item>
      <a-descriptions-item label="身份证姓名">
        <span class="font-bold">{{ baseInfo.identityCardName }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="身份证号" :span="2">
        <span class="font-bold">{{ baseInfo.identityCardNo }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="身份证证件" :span="3">
        <img
            class="w-100"
            v-for="(item, index) in [baseInfo.identityCardFrontImgurl, baseInfo.identityCardReverseImgurl]"
            :key="index"
            :src="item"
            alt=""
            @click="handleShowBigImg(index, [baseInfo.identityCardFrontImgurl, baseInfo.identityCardReverseImgurl])"
        />
        <a-button v-if="baseInfo.identityCardFrontImgurl" class="ml-20" size="small" @click="handleUpdateUserCardInfo(baseInfo)">更新身份证信息</a-button>
      </a-descriptions-item>
      <a-descriptions-item label="退货-收款人姓名">
        <span class="font-bold">{{ baseInfo.refundUserName }}</span>
        <a-button
            class="ml-20"
            size="small"
            type="primary"
            @click="handleShowEditAddress"
        ><a-icon type="edit"/>编辑</a-button>
      </a-descriptions-item>
      <a-descriptions-item label="退货-收款人手机号" :span="2">
        <span class="font-bold">{{ baseInfo.refundUserPhone }}</span>
        <a-button
            class="ml-20"
            size="small"
            type="primary"
            @click="handleShowEditAddress"
        ><a-icon type="edit"/>编辑</a-button>
      </a-descriptions-item>
      <a-descriptions-item label="退货-收款人地址" :span="3">
        <span class="font-bold" v-if="baseInfo.refundProvince">{{ baseInfo.refundProvince }}</span>
        <span class="font-bold ml-10" v-if="baseInfo.refundCity">{{ baseInfo.refundCity }}</span>
        <span class="font-bold ml-10" v-if="baseInfo.refundCounty">{{ baseInfo.refundCounty }}</span>
        <span class="font-bold">{{ baseInfo.refundUserAddress }}</span>
        <a-button
            class="ml-20"
            size="small"
            type="primary"
            @click="handleShowEditAddress"
        ><a-icon type="edit"/>编辑</a-button>
      </a-descriptions-item>
      <a-descriptions-item label="注册时间" :span="1">
        <span class="font-bold">{{ baseInfo.createTime }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="过期时间" :span="2">
        <span class="font-bold">{{ baseInfo.shopExpireTime }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="支付时间" :span="1">
        <span class="font-bold">{{ baseInfo.paytime }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="支付金额" :span="2">
        <span class="font-bold">{{ baseInfo.payMoney }}</span>
      </a-descriptions-item>
      <a-descriptions-item label="店铺标记" :span="3">
        <div class="desc-box">
          <div v-if="!showSelectRefuseAuto" class="flex-start">
            <a-tag
                color="red"
                v-if="!showSelect && (baseInfo.reviewTipMark || shopTagDescText)"
            >{{ confirmStatus ? shopTagDescText : baseInfo.reviewTipMark }}
              <a-icon class="ml-10" type="close-circle" @click="handleDeleteTag()"/>
            </a-tag>
            <a-select
                v-if="showSelect"
                allowClear
                v-model="shopTagDescTextById"
                style="width: 400px;"
                placeholder='请选择标记'
                @change="handleChangeSelectRefuseType"
            >
              <a-select-option
                  v-for="item in shopTagDescList"
                  :key="item.id"
                  :value='item.id'
              >
                <span>{{ item.value }}</span>
                <a-icon v-if="item.icon" :type="item.icon" style="color: #2d8cf0;margin-left: 5px;"/>
              </a-select-option>
            </a-select>
            <a-icon
                v-if="showSelect"
                @click="handleConfirmShopTag"
                type="check-circle"
                style="margin-left: 20px;font-size: 24px;color: dodgerblue;cursor: pointer"
            />
          </div>
          <div v-else class="flex-start">
            <a-input
                allowClear
                v-model="shopTagDescText"
                style="width: 400px"
                placeholder='输入店铺标记'
            ></a-input>
            <a-icon
                v-if="showSelect"
                @click="handleConfirmShopTag"
                type="check-circle"
                style="margin-left: 20px;font-size: 24px;color: dodgerblue;cursor: pointer"
            />
            <a-tooltip placement="top">
              <template slot="title">
                <span>关闭自定义输入店铺标记</span>
              </template>
              <a-icon
                  @click="handleCloseAuto"
                  type="close-circle"
                  style="margin-left: 20px;font-size: 24px;color: red;cursor: pointer"
              />
            </a-tooltip>
          </div>
          <div>
            <a-button class="ml-5" :icon="showSelect ? 'close' : 'edit'" size="small" @click="handleChangeBondPrice(baseInfo.reviewTipMark)"/></div>
        </div>
      </a-descriptions-item>
      <a-descriptions-item label="营业执照">
        <img
            class="w-100"
            :src="baseInfo.businessLicense"
            @click="handleShowBigImg(0, [baseInfo.businessLicense])"
        />
      </a-descriptions-item>
      <a-descriptions-item label="文物经营许可" :span="2">
        <img
            class="w-100"
            :src="baseInfo.relicsLicense"
            @click="handleShowBigImg(0, [baseInfo.relicsLicense])"
        />
      </a-descriptions-item>
      <a-descriptions-item v-if="checkType" label="审核店铺" :span="3">
       <div class="flex-start-center">
         <a-input v-model="baseInfo.reviewMsg" type="textarea" :auto-size="{ minRows: 6, maxRows: 10 }" placeholder="拒绝原因"/>
         <a-button type="danger" class="ml-20" @click="handleCheckOverShop(-1)">拒绝</a-button>
         <a-button type="primary" class="ml-20"  @click="handleCheckOverShop(2)">通过</a-button>
       </div>
      </a-descriptions-item>
      <a-descriptions-item label="同身份证号店铺：" :span="3">
        <div class="flex-start-center">
          <a-tag
              v-for="(item, index) in sameShopList"
              :key="index"
              color="blue"
              class="mr-5 cur-pot mb-10"
              @click="handleGoToShopList(item)"
          >{{ item.shopName }}</a-tag>
        </div>
      </a-descriptions-item>
    </a-descriptions>
<!-- 修改地址 -->
    <ShopAddressEditorPopup ref="shopAddressEditorPopupEl" @setBaseInfo="handleSetBaseInfo($event)"/>
<!-- 更新用户身份信息   -->
    <ShopUserCardInfoUpdatePopup ref="shopUserCardInfoUpdatePopupEl" @updateUserCardInfo="handleUpdateUserCardInfoSuccess($event)"/>
  </a-drawer>
</template>
<script>
import {shopTagDescList} from "@/views/cmsPage/shopManage/_data"
import ShopAddressEditorPopup from "@/views/cmsPage/shopManage/shopInfoList/_components/ShopAddressEditorPopup"
import ShopUserCardInfoUpdatePopup
  from "@/views/cmsPage/shopManage/shopInfoList/_components/ShopUserCardInfoUpdatePopup/index.vue"
import {getSameShopByUserId} from "@/views/cmsPage/shopManage/shopInfoList/_apis"
import {goToOtherPage} from "@/untils/otherEvent"

export default {
  components: {ShopAddressEditorPopup, ShopUserCardInfoUpdatePopup},
  data() {
    return {
      showDrawer: false,
      baseInfo: '',
      starValue: 0,
      starDesc: [
        '品相较差；新手业余被割了韭菜卖家；批量走垃圾；无服务商家',
        '普品居多',
        '中上好货，有小部分好货',
        '优质好货，价值小点或服务差点',
        '大部分优质好货，大货',
        '甄选极致店铺'
      ],
      shopTagDescList,
      shopTagDescText: undefined,
      shopTagDescTextById: undefined,
      showSelectRefuseAuto: false,
      showSelect: false,
      confirmStatus: false,
      editorStatus: false,
      checkType: '',
      defaultAddressList: [],
      sameShopList: [],
    }
  },
  methods: {
    /** 去往店铺列表 */
    handleGoToShopList(item) {
      goToOtherPage(`/cms/usershop/mannagelist?keyword=${item.userId}`)
    },
    /** 用户信息封信成功 */
    handleUpdateUserCardInfoSuccess(data) {
      this.$emit('updateUserCardInfoSuccess', data)
    },
    /** 更新用户身份证信息 */
    handleUpdateUserCardInfo() {
      this.$refs.shopUserCardInfoUpdatePopupEl.show({
        id: this.baseInfo.id,
        userId: this.baseInfo.userId,
        identityCardFrontImgurl: this.baseInfo.identityCardFrontImgurl,
        identityCardReverseImgurl: this.baseInfo.identityCardReverseImgurl,
      })
    },
    /** 删除订单 */
    async handleDeleteTag() {
      const res = await this.axios.post('/dq_admin/userShopInfo/editShop',{
        id: this.baseInfo.id,
        reviewTipMark: ''
      })
      if (res.status !== '200') return
      this.shopTagDescText = ''
      this.baseInfo.reviewTipMark = ''
      this.$message.success('删除成功')
      this.$emit('setShopTagText', {reviewTipMark: '', id: this.baseInfo.id})
    },
    /** 拒绝或通过店铺审核 */
    async handleCheckOverShop(type) {
      // if (!this.baseInfo.refundProvince) return this.$message.warn('省市区不能为空')
      this.baseInfo.reviewStatus = type;
      const res = await this.axios.post('/dq_admin/userShopInfo/reviewShop',this.baseInfo)
      if(res.status == 200){
        const {message} = res
        this.$message.success(message)
        this.editorStatus = true
        this.onClose()
      }
    },
    handleShowBigImg(index, list) {
      list = list.map(el => ({'img_url': el}))
      this.$previewImg({
        list: list,
        baseImgField: "img_url",
        showMute: false,
        current: index
      });
    },
    /** 编辑完成基础信息 */
    handleSetBaseInfo(data) {
      Object.assign(this.baseInfo, data)
      this.editorStatus = true
    },
    /** 编辑地址信息 */
    handleShowEditAddress() {
      this.$refs.shopAddressEditorPopupEl.show({
        id: this.baseInfo.id,
        shopName: this.baseInfo.shopName,
        shopInfo: this.baseInfo.shopInfo,
        refundUserPhone: this.baseInfo.refundUserPhone,
        refundUserName: this.baseInfo.refundUserName,
        refundProvince: this.baseInfo.refundProvince || undefined,
        refundCity: this.baseInfo.refundCity || undefined,
        refundCounty: this.baseInfo.refundCounty || undefined,
        refundUserAddress: this.baseInfo.refundUserAddress,
      })
    },
    /** 编辑数据 */
    handleChangeBondPrice() {
      this.showSelect = !this.showSelect
    },
    /** 改变选择 */
    handleChangeSelectRefuseType(e) {
      if (e === 'auto') {
        this.showSelectRefuseAuto = true
        this.shopTagDescText = undefined
      } else {
        this.shopTagDescText = this.shopTagDescList.find(el => el.id === this.shopTagDescTextById).value
      }
    },
    /** 关闭自定义店铺输入店铺标记 */
    handleCloseAuto() {
      this.showSelectRefuseAuto = false
      this.shopTagDescTextById = undefined
      this.shopTagDescText = undefined
    },
    /** 确认标记 */
    async handleConfirmShopTag() {
      if (!this.shopTagDescText) return this.$message.warn('请输入或选择原因')
      const res = await this.axios.post('/dq_admin/userShopInfo/editShop',{
        id: this.baseInfo.id,
        reviewTipMark: this.shopTagDescText
      })
      if (res.status !== '200') return
      this.$message.success('更新成功')
      this.showSelect = false
      this.showSelectRefuseAuto = false
      this.confirmStatus = true
      this.shopTagDescTextById = undefined
      this.$emit('setShopTagText', {reviewTipMark: this.shopTagDescText, id: this.baseInfo.id})
    },
    /** 确定当前星级 */
    async handleSetShopStar() {
      const res = await this.axios.post('/dq_admin/userShopInfo/editShopStar',{
        id: this.baseInfo.id,
        star: this.starValue
      })
      if (res.status !== '200') return
      this.$message.success('设置成功')
      this.$emit('setStar', {star: this.starValue, id: this.baseInfo.id})
    },
    /** 去往用户列表页 */
    handleGoToUserInfo(userId) {
      let path = "/cms/dqplatform/usermannage";
      let query = { userId: userId };
      const{ href } = this.$router.resolve({path,query});
      window.open(href)
    },
    /** 展示信息 */
    async show(data, type) {
      if (type) this.checkType = type
      this.starValue = data.star
      this.baseInfo = data
      this.shopTagDescText = data.reviewTipMark
      await this.getSameShopUser(data.userId)
      this.showDrawer = true
    },
    /** 获取同店用户 */
    async getSameShopUser(id) {
      const res = await getSameShopByUserId({userId: id})
      if (res.status !== '200') return
      this.sameShopList = res.data
    },
    /** 关闭信息 */
    onClose() {
      this.showSelect = false
      this.shopTagDescText = undefined
      this.shopTagDescTextById = undefined
      this.showDrawer = false
      if (this.editorStatus) {
        this.editorStatus = false
        this.$emit('changeEdit', this.baseInfo)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.desc-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.font-bold {
  font-weight: bold;

}
.color-blue {
  color: #2d8cf0;
}
.text-underline {
  text-decoration: underline;
}
.text-cursor {
  cursor: pointer;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>