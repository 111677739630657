import instance from "@/axios"

/**  获取积分明细 */
export const getScoreLineInfo = async (params) => {
    return instance({
        url: '/dq_admin/userCreditDetail/detailPageListByUserId',
        method: 'GET',
        params: params
    })
}
/**  领先数据 */
export const getUserScoreInfo = async (params) => {
    return instance({
        url: '/dq_admin/userCredit/userCreditDetailList',
        method: 'GET',
        params: params
    })
}

/** 用户管理-禁言 */
export const userBanSpeakApi = async (params) => {
    return instance({
        url: '/dq_admin/user/mute',
        method: 'get',
        params: params
    })
}

/** 用户管理-培训 */
export const userStudyApi = async (params) => {
    return instance({
        url: '/dq_admin/userShopQuestionAnswer/edit',
        method: 'post',
        data: params
    })
}
/** 用户管理-取消培训 */
export const cancelUserStudyApi = async (params) => {
    return instance({
        url: '/dq_admin/userShopQuestionAnswer/delById',
        method: 'get',
        params: params
    })
}

/** 保证金管理-占用明细 */
export const getBondPriceOccupationLineApi = async (params) => {
    return instance({
        url: '/dq_admin/userDeposit/depositOccupationDetailList',
        method: 'get',
        params: params
    })
}