<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input v-model="params.keywordUser" allowClear size='small' style="width:150px;" placeholder='昵称/店铺名/userId'></a-input>
        <a-select allowClear v-model="params.reviewStatus" size='small' style="width:100px;margin:5px;" placeholder='刷选'>
          <a-select-option :value='0'>待审核</a-select-option>
          <a-select-option :value='2'>审核通过</a-select-option>
          <a-select-option :value='-1'>已拒绝</a-select-option>
        </a-select>
        <a-select allowClear v-model="params.shopIsPaid" size='small' style="width:100px;margin:5px;" placeholder='支付状态'>
          <a-select-option :value='0'>未支付</a-select-option>
          <a-select-option :value='1'>已支付</a-select-option>
        </a-select>
        <a-select allowClear v-model="params.lianlianUserStatus" size='small' style="width:200px;margin:5px;" placeholder='连连开户状态'>
          <a-select-option
              v-for="item in lianLianShopStatus"
              :key="item.value"
              :vlaue="item.value"
          >{{ item.name }}</a-select-option>
        </a-select>

<!--    时间搜索    -->
        <a-range-picker size="small" :placeholder="['开始日期','结束日期']" @change="handleChangeRangePicker" />
<!--    星级筛选    -->
        <a-select allowClear v-model="params.star" size='small' style="width:150px;margin:5px;" placeholder='请选择星级'>
          <a-select-option
              v-for="item in 6"
              :key="item"
              :value='7 - item'>{{ (7 - item) + '星' }}</a-select-option>
        </a-select>

        <a-button @click="search" size='small' type="primary" style="margin-left:10px;">搜索</a-button>
      </div>
    </div>
    <div class="table-content" >
      <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">
<!--   用户ID     -->
        <div slot="itemUserIdSlot" slot-scope="row">
          <div class="color-green">{{ row.userName }} <span class="color-black">【{{ row.userId }}】</span> </div>
          <div style="margin-top: 10px;">
            <a-button type="primary" size="small" @click="handleShowShopPriceLine(row)">待提现货款明细</a-button>
          </div>
        </div>
<!--   连连用户开户状态     -->
        <div slot="itemLianLianUserStatusSlot" slot-scope="row">
          <span :class="lianLianShopStatusClassType[row.lianlianUserStatus]">{{ computedLianLianStatusTypeMapText(row.lianlianUserStatus) }}</span>
        </div>
        <div slot="remark" slot-scope="row">
          <div v-if="!row.showEditor" v-html="row.remark" style="white-space:pre-line;"></div>
          <a-button v-if="!row.showEditor" @click="handleShowChangeRemark(row)" size="small" style="margin-top: 10px">跟进备注</a-button>
          <div
              v-if="row.showEditor"
              style="width: 100%;display: flex;justify-content: center;align-items: center"
          >
              <textarea
                  :id="'textareaEl' + row.id"
                  type="text"
                  style="width: 400px;"
                  v-model="selectRemark"
                  rows="6"
                  placeholder="跟进备注"
              ></textarea>
            <div style="margin-left: 10px;display: flex;flex-direction: column;justify-content: center;align-items: center">
              <a-icon @click="handleConfirm(row)" type="check-circle" style="font-size: 24px;color: blue;cursor: pointer;"/>
              <a-icon @click="handleClose(row)" type="close-circle" style="font-size: 24px;color: red;margin-top: 20px;cursor: pointer"/>
            </div>
          </div>
        </div>
        <!--     拍品比率           -->
        <div slot="auctionCountRatio" slot-scope="row">
            <div @click="handleGoToUserAuctionList(row)">
              <div class="color-blue cur-pot">精品数：{{ row.distributeJingpinPaiPinCount || 0 }}</div>
              <div class="color-black">拍品数：{{ row.distributePaiPinCount || 0 }}</div>
            </div>
        </div>
<!--    在架数/拍品数    -->
        <div slot="itemAuctionOrOnLineSlot" slot-scope="row">
          <div @click="handleGoToUserAuctionList(row)">
            <div class="color-blue cur-pot">拍品在架：{{ row.paipinOnSelfNum || 0 }}</div>
            <div class="color-black">拍品总数：{{ row.paipinTotalNum || 0 }}</div>
          </div>
        </div>
<!--    在架数/一口价数    -->
        <div slot="itemFixedOrOnLineSlot" slot-scope="row">
          <div @click="handleGoToUserFixedList(row)">
            <div class="color-blue cur-pot">一口价在架：{{ row.yikoujiaOnSelfNum || 0 }}</div>
            <div class="color-black">一口价总数：{{ row.yikoujiaTotalNum || 0 }}</div>
          </div>
        </div>
<!--    保证金比率    -->
        <div slot="auctionBondPriceSlot" slot-scope="row">
          <span :class="row.sellerDepositMoney >= row.minDepositMoney ? 'color-green' : 'color-red'">
            {{ row.sellerDepositMoney || 0 }}
          </span>/
          <span>{{ row.minDepositMoney }}</span>
        </div>
<!--    店铺星级    -->
        <div slot-scope="row" slot="shopStarLevelSlot">
          <a-rate v-model="row.star" disabled :count="6"/>
        </div>
        <div slot="setup" slot-scope="row">
          <a-button v-if="row.reviewStatus == 0 && row.payStatus == 1" @click="reviewClick(row)" size='small'>审核</a-button>
          <div v-if="row.payStatus" style="margin-top: 10px;">
            <a-dropdown>
              <a-button size="small" type="danger">退开店费</a-button>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a-popconfirm
                        title="确定退部分开店费吗？（部分开店费根据用户使用时间来退）"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="handleShowShopBaseInfo(row, 'returnShop', 'month')"
                    >
                      <a-button size="small" type="primary">按天退开店费</a-button>
                    </a-popconfirm>
                  </a-menu-item>
                  <a-menu-item>
                    <a-popconfirm
                        title="确定退全额开店费吗？"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="handleShowShopBaseInfo(row, 'returnShop', 'all')"
                    >
                      <a-button size="small" type="primary">全额退开店费</a-button>
                    </a-popconfirm>
                  </a-menu-item>
                  <a-menu-item v-if="row.payMoney && false">
                    <a-popconfirm
                        title="确定退全额开店费吗？"
                        ok-text="Yes"
                        cancel-text="No"
                        @confirm="handleShowShopBaseInfo(row, 'returnShop', 'all')"
                    >
                      <a-button size="small" type="primary">退500开电费</a-button>
                    </a-popconfirm>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
          <div style="margin-top: 10px;">
            <a-button type="primary" size="small" @click="handleShowShopBaseInfo(row, 'line')">店铺明细</a-button>
          </div>
          <div style="margin-top: 10px;">
            <a-button type="danger" size="small" @click="handleShowShopBaseInfo(row, 'penalize')">惩罚</a-button>
          </div>
        </div>
        <!--      支付店铺保证金    -->
        <div slot="payBondPriceSlot" slot-scope="row" >
          <span>{{ row.minDepositMoney ? row.minDepositMoney + '元' : '无' }}</span>
          <a-button class="ml-5" icon="edit" size="small" @click="handleChangeBondPrice(row)">
          </a-button>
        </div>
      </a-table>
    </div>
    <a-modal centered :width='500' v-model="showModal" @ok='checkForms' @cancel='cancel'>
      <div style="height:100px;overflow-y:auto;">
        <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
          <a-form-model-item label='用户id' prop='userId'>
            <a-input placeholder='请输入用户ID' v-model="forms.userId"></a-input>
          </a-form-model-item>
          <a-form-model-item label='店铺名称' prop='shopName'>
            <a-input placeholder='请输入店铺名称' v-model="forms.shopName"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <!--   展示店铺基本信息   -->
    <ShopBaseInfoPopup
        ref="shopBaseInfoPopupEl"
        @setStar="handleSetSuccessStar($event)"
        @setShopTagText="handleSetShopTagText($event)"
        @changeEdit="handleChangeEdit($event)"
        @updateUserCardInfoSuccess="handleUpdateUserCardInfoSuccess($event)"
    />
    <ShopChangeBondPricePopup
        ref="shopChangeBondPricePopupEl"
        @successEdit="getData"
    />
    <ShopPenalizeEditor ref="shopPenalizeEditorEl" @blackSuccess="getData(params)"/>
<!-- 店铺金额明细   -->
    <ShopPriceLinePopup ref="shopPriceLinePopupEl"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import ShopBaseInfoPopup from "@/views/cmsPage/shopManage/shopInfoList/_components/ShopBaseInfoPopup";
import ShopChangeBondPricePopup from "@/views/cmsPage/shopManage/shopInfoList/_components/ShopChangeBondPricePopup";
import ShopPenalizeEditor from "@/views/cmsPage/shopManage/shopInfoList/_components/ShopPenalizeEditor";
import {cityOptions} from "@/untils/cityOption"
import ShopPriceLinePopup from "@/views/cmsPage/shopManage/shopInfoList/_components/ShopPriceLinePopup"
import {
  LIAN_LIAN_SHOP_STATUS_TYPE_LIST,
  LIAN_LIAN_USER_STATUS_CLASS_TYPE,
  lianLianStatusTypeMapText
} from "@/views/cmsPage/shopManage/shopInfoList/_data"
import {goToOtherPage} from "@/untils/otherEvent"
export default {
  components: {ShopBaseInfoPopup, ShopChangeBondPricePopup, ShopPenalizeEditor, ShopPriceLinePopup},
  data(){
    return {
      lianLianShopStatus: LIAN_LIAN_SHOP_STATUS_TYPE_LIST,
      lianLianShopStatusClassType: LIAN_LIAN_USER_STATUS_CLASS_TYPE,
      cityOptions,
      defaultAddressList: [],
      columns:[
        {title: "用户ID", scopedSlots: { customRender: "itemUserIdSlot" }, width: 200},
        {title: "昵称/店铺",dataIndex: "userName"},
        {title: "跟进/备注",align: "center",scopedSlots: { customRender: "remark" }, width: 200},
        {
          title: "精品数/拍品数",
          align: "center",
          scopedSlots: { customRender: "auctionCountRatio" },
          width: 150,
          sorter: true,
          key: 'tag_auction',
          defaultSortOrder: ''
        },
        { title: "在架数/拍品数", align: "center", scopedSlots: { customRender: "itemAuctionOrOnLineSlot" }, width: 150,},
        { title: "在架数/一口价数", align: "center", scopedSlots: { customRender: "itemFixedOrOnLineSlot" }, width: 150,},
        {
          title: "剩余保证金/应缴纳最低保证金",
          align: "center",
          scopedSlots: { customRender: "auctionBondPriceSlot" },
          width: 150,
        },
        // { title: "店铺星级", align: "center", scopedSlots: { customRender: "shopStarLevelSlot" }, width: 200,},
        {
          title: "店铺状态",
          dataIndex: "reviewStatus",
          width: 100,
          align: 'center',
          customRender: (text) => {
            const inner = text;
            if (inner == '-1') {
              return <span style="color:red">已拒绝</span>;
            } else if (inner == '0') {
              return <span style="color:gray">待审核</span>;
            } else if (inner == '2') {
              return <span style="color:green">审核通过</span>;
            }
          },
        },
        {
          title: "连连开户状态",
          width: 150,
          align: 'center',
          scopedSlots: { customRender: "itemLianLianUserStatusSlot" }
        },
        {
          title: "支付状态",
          dataIndex: "payStatus",
          customRender: (text) => {
            const inner = text;
            if (inner == '1') {
              return <span style="color:green">已支付</span>;
            }
            if (inner == '0') {
              return <span style="color:red">未支付</span>;
            }
          },
        },
        {title:'支付金额',align:'center',dataIndex:'payMoney'},
        {title:'最少发拍保证金',align:'center',scopedSlots: { customRender: 'payBondPriceSlot'},width: 150},
        {title:'店铺注册时间',align:'center',dataIndex:'createTime'},
        {title: "操作",align: "center",scopedSlots: { customRender: "setup" },},
      ],
      data:[],
      forms:{
        userId:'',
      },
      reviewFroms:{
      },
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
      rules:{
        userId:[{ required: true, message: '用户ID', trigger: 'blur' }],
      },
      showModal:false,
      reviewModal:false,
      visible:false,
      params:{
        pageNum:1,
        pageSize:10,
        userName: undefined,
        goodSortType: 0,
        lianlianUserStatus: undefined,
        publishStartTime: undefined,
        publishEndTime: undefined,
        star: undefined
      },
      pagination:{
        total:0,
        current:1,
        showTotal:(total)=>`共${total}条`,
      },
      loading:false,
      clicked: false,
      hovered: false,
      innerTitle: "店铺审核",
      operType:'',
      showEditor: false,
      selectRemark: '',
      sortType: '',
    }
  },

  computed:{
    ...mapState(['ip']),
    computedLianLianStatusTypeMapText() {
      return (value) => lianLianStatusTypeMapText(value)
    }
  },
  created(){
    const { keyword } = this.$route.query;
    this.$set(this.params, "keywordUser", keyword);
    this.getData(this.params)
  },
  methods:{
    /** 用户的身份证信息更新成功 */
    handleUpdateUserCardInfoSuccess(data) {
      this.data.forEach(el => {
        if (el.id === data.id) {
          el.identityCardFrontImgurl = data.cardFront
          el.identityCardReverseImgurl = data.cardBack
        }
      })
    },
    /** 店铺金额明细 */
    handleShowShopPriceLine(row) {
      this.$refs.shopPriceLinePopupEl.show(row)
    },
    reviewClick(row) {
      const data = JSON.parse(JSON.stringify(row))
      this.$refs.shopBaseInfoPopupEl.show(data, 'checkShop')
    },
    /** 展示店铺基本信息 */
    handleShowShopBaseInfo(row, type, returnShopType) {
      if (type === 'line') {
        const data = JSON.parse(JSON.stringify(row))
        this.$refs.shopBaseInfoPopupEl.show(data)
      } else if (type === 'returnShop') {
        this.returnShopFee(row, returnShopType)
      } else {
        this.$refs.shopPenalizeEditorEl.show(row.userId)
      }
    },
    /** 修改省市区 */
    handleChangeArea(e) {
      this.defaultAddressList = e
    },
    /** 改变基础数据 */
    handleChangeEdit(data) {
      this.getData(this.params)
    },
    /** 去往拍品列表 */
    handleGoToUserAuctionList(row) {
      const {userId} = row
      const routeData = this.$router.resolve({
        path: "/cms/currencymannage/auctiongoods",
        query: {
          userId: userId
        }
      });
      window.open(routeData.href, '_blank');
    },
    /** 去往一口价页面 */
    handleGoToUserFixedList(row) {
      const url = `/cms/currencymannage/goods?userName=${row.userId}`
      goToOtherPage(url)
    },
    /** 设置星级成功 */
    handleSetSuccessStar(data) {
      this.data.forEach(el => {
        if (el.id === data.id) {
          el.star = data.star
        }
      })
    },
    /** 设置店铺标记 */
    handleSetShopTagText(data) {
      this.data.forEach(el => {
        if (el.id === data.id) {
          el.reviewTipMark = data.reviewTipMark
        }
      })
    },
    /** 选择时间范围 */
    handleChangeRangePicker(range, rangeArray) {
      if (rangeArray && rangeArray.length) {
        this.params.publishStartTime = rangeArray[0]
        this.params.publishEndTime = rangeArray[1]
      } else {
        this.params.publishEndTime = undefined
        this.params.publishStartTime = undefined
      }
    },
    /** 修改需要缴纳的发拍保证金  */
    handleChangeBondPrice(row) {
      this.$refs.shopChangeBondPricePopupEl.show({id: row.id, price: row.minDepositMoney})
    },
    /** 推开店费 */
    async returnShopFee(row, type) {
      const res = await this.axios('/dq_admin/userShopInfo/returnShopFee', {
        params: {
          id: row.id,
          returnType: type
        }
      })
      if (res.status !== '200') return
      this.$message.success('退款完成')
      await this.getData(this.params)
    },
    /** 取消编辑 */
    handleClose(row) {
      this.data.forEach((el, index) => {
        if (el.id === row.id) {
          this.$set(this.data[index], 'showEditor',  false)
        } else {
          this.data[index].showEditor = false
        }
      })
    },
    /** 确定编辑编辑 */
    async handleConfirm(row) {
      const res = await this.axios.post('/dq_admin/userShopInfo/editShop',{
        id: row.id,
        remark: this.selectRemark
      })
      if (res.status !== '200') return
      this.$message.success('更新成功')
      await this.getData(this.params)
    },
    /** 是否进行编辑 */
    handleShowChangeRemark(row) {
      this.selectRemark = row.remark || undefined
      this.data.forEach((el, index) => {
        if (el.id === row.id) {
          this.$set(this.data[index], 'showEditor',  true)
        } else {
          this.data[index].showEditor = false
        }
      })
      this.$nextTick(() => {
        const textAreaEl = document.getElementById(`textareaEl${row.id}`)
        textAreaEl.selectionStart = textAreaEl.value.length;
        textAreaEl.scrollTop = textAreaEl.scrollHeight;
      })
    },
    async getData(params){
      this.loading = true
      const res = await this.axios('/dq_admin/userShopInfo/list',{params:params})
      this.loading = false
      const {records,total} = res.data
      records.forEach(el => {
        el.showEditor = false
      })
      this.data = records
      this.pagination.total = total
    },
    async search(){
      this.params.goodSortType = 0
      this.params.pageNum = 1
      this.$set(this.pagination, 'current', 1)
      delete this.columns[5].sorter
      await this.getData(this.params)
      this.columns[5].sorter = true
    },
    changePage(page, filter, sort){
      const {current} = page
      if (current === this.pagination.current) {
        if (sort.order === 'descend') {
          this.params.goodSortType = 1
        } else if (sort.order === 'ascend') {
          this.params.goodSortType = -1
        } else {
          this.params.goodSortType = 0
        }
        this.pagination.current = 1
        this.params.pageNum = 1
      } else {
        this.pagination.current = current
        this.params.pageNum = current
      }
      this.getData(this.params)
    },
    openModal(row) {
      this.showModal = true;
    },
    cancel(){
      this.forms = {rankIcon:'',userId:''};
      this.reviewFroms = {};
      this.$refs.ruleForm.resetFields();
    },
    checkForms(){
      this.$refs.ruleForm.validate(valid => {
        if(valid){
          this.submit(this.forms)
        }else{
          return false;
        }
      });
    },
    async submit(){
      const res = await this.axios.post('/dq_admin/userShopInfo/add',this.forms)
      if(res.status == 200){
        const {message} = res
        this.$message.success(message)
        this.getData(this.params)
        this.showModal = false
        this.cancel()
      }
    },
    activeShop(row) {
      const id = row.id
      this.axios("/dq_admin/userShopInfo/activeShop", {
        params: { id: id },
      }).then((res) => {
        this.getData(this.params);
        this.$message.success(res.message);
      });
    },

    handleClickChange(visible) {
      this.clicked = visible;
      this.hovered = false;
    },
  }
}

function d(a,b){
  // setTimeout((

  // ),1000);
  // this.params.fansSort = 0
  this.getData(this.params)
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
  .table-content{
    flex:1;
    overflow-y: auto;
  }
}
.take-box{width:100%;max-height:700px;}
.ml-5 {
  margin-left: 5px;
}
.color-red  {
  color: red;
}
.color-green {
  color: green;
}
.cur-pot {
  cursor: pointer;
}
</style>