var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c("div", { staticClass: "search-attr" }, [
        _c(
          "div",
          [
            _c("a-input", {
              staticStyle: { width: "150px" },
              attrs: {
                allowClear: "",
                size: "small",
                placeholder: "昵称/店铺名/userId"
              },
              model: {
                value: _vm.params.keywordUser,
                callback: function($$v) {
                  _vm.$set(_vm.params, "keywordUser", $$v)
                },
                expression: "params.keywordUser"
              }
            }),
            _c(
              "a-select",
              {
                staticStyle: { width: "100px", margin: "5px" },
                attrs: { allowClear: "", size: "small", placeholder: "刷选" },
                model: {
                  value: _vm.params.reviewStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "reviewStatus", $$v)
                  },
                  expression: "params.reviewStatus"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 0 } }, [
                  _vm._v("待审核")
                ]),
                _c("a-select-option", { attrs: { value: 2 } }, [
                  _vm._v("审核通过")
                ]),
                _c("a-select-option", { attrs: { value: -1 } }, [
                  _vm._v("已拒绝")
                ])
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "100px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "支付状态"
                },
                model: {
                  value: _vm.params.shopIsPaid,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "shopIsPaid", $$v)
                  },
                  expression: "params.shopIsPaid"
                }
              },
              [
                _c("a-select-option", { attrs: { value: 0 } }, [
                  _vm._v("未支付")
                ]),
                _c("a-select-option", { attrs: { value: 1 } }, [
                  _vm._v("已支付")
                ])
              ],
              1
            ),
            _c(
              "a-select",
              {
                staticStyle: { width: "200px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "连连开户状态"
                },
                model: {
                  value: _vm.params.lianlianUserStatus,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "lianlianUserStatus", $$v)
                  },
                  expression: "params.lianlianUserStatus"
                }
              },
              _vm._l(_vm.lianLianShopStatus, function(item) {
                return _c(
                  "a-select-option",
                  { key: item.value, attrs: { vlaue: item.value } },
                  [_vm._v(_vm._s(item.name))]
                )
              }),
              1
            ),
            _c("a-range-picker", {
              attrs: { size: "small", placeholder: ["开始日期", "结束日期"] },
              on: { change: _vm.handleChangeRangePicker }
            }),
            _c(
              "a-select",
              {
                staticStyle: { width: "150px", margin: "5px" },
                attrs: {
                  allowClear: "",
                  size: "small",
                  placeholder: "请选择星级"
                },
                model: {
                  value: _vm.params.star,
                  callback: function($$v) {
                    _vm.$set(_vm.params, "star", $$v)
                  },
                  expression: "params.star"
                }
              },
              _vm._l(6, function(item) {
                return _c(
                  "a-select-option",
                  { key: item, attrs: { value: 7 - item } },
                  [_vm._v(_vm._s(7 - item + "星"))]
                )
              }),
              1
            ),
            _c(
              "a-button",
              {
                staticStyle: { "margin-left": "10px" },
                attrs: { size: "small", type: "primary" },
                on: { click: _vm.search }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("a-table", {
            attrs: {
              pagination: _vm.pagination,
              loading: _vm.loading,
              columns: _vm.columns,
              "data-source": _vm.data,
              rowKey: function(record, n) {
                return n
              }
            },
            on: { change: _vm.changePage },
            scopedSlots: _vm._u([
              {
                key: "itemUserIdSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c("div", { staticClass: "color-green" }, [
                      _vm._v(_vm._s(row.userName) + " "),
                      _c("span", { staticClass: "color-black" }, [
                        _vm._v("【" + _vm._s(row.userId) + "】")
                      ])
                    ]),
                    _c(
                      "div",
                      { staticStyle: { "margin-top": "10px" } },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.handleShowShopPriceLine(row)
                              }
                            }
                          },
                          [_vm._v("待提现货款明细")]
                        )
                      ],
                      1
                    )
                  ])
                }
              },
              {
                key: "itemLianLianUserStatusSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "span",
                      {
                        class:
                          _vm.lianLianShopStatusClassType[
                            row.lianlianUserStatus
                          ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.computedLianLianStatusTypeMapText(
                              row.lianlianUserStatus
                            )
                          )
                        )
                      ]
                    )
                  ])
                }
              },
              {
                key: "remark",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      !row.showEditor
                        ? _c("div", {
                            staticStyle: { "white-space": "pre-line" },
                            domProps: { innerHTML: _vm._s(row.remark) }
                          })
                        : _vm._e(),
                      !row.showEditor
                        ? _c(
                            "a-button",
                            {
                              staticStyle: { "margin-top": "10px" },
                              attrs: { size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.handleShowChangeRemark(row)
                                }
                              }
                            },
                            [_vm._v("跟进备注")]
                          )
                        : _vm._e(),
                      row.showEditor
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                display: "flex",
                                "justify-content": "center",
                                "align-items": "center"
                              }
                            },
                            [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectRemark,
                                    expression: "selectRemark"
                                  }
                                ],
                                staticStyle: { width: "400px" },
                                attrs: {
                                  id: "textareaEl" + row.id,
                                  type: "text",
                                  rows: "6",
                                  placeholder: "跟进备注"
                                },
                                domProps: { value: _vm.selectRemark },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.selectRemark = $event.target.value
                                  }
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    display: "flex",
                                    "flex-direction": "column",
                                    "justify-content": "center",
                                    "align-items": "center"
                                  }
                                },
                                [
                                  _c("a-icon", {
                                    staticStyle: {
                                      "font-size": "24px",
                                      color: "blue",
                                      cursor: "pointer"
                                    },
                                    attrs: { type: "check-circle" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleConfirm(row)
                                      }
                                    }
                                  }),
                                  _c("a-icon", {
                                    staticStyle: {
                                      "font-size": "24px",
                                      color: "red",
                                      "margin-top": "20px",
                                      cursor: "pointer"
                                    },
                                    attrs: { type: "close-circle" },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleClose(row)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              },
              {
                key: "auctionCountRatio",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.handleGoToUserAuctionList(row)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "color-blue cur-pot" }, [
                          _vm._v(
                            "精品数：" +
                              _vm._s(row.distributeJingpinPaiPinCount || 0)
                          )
                        ]),
                        _c("div", { staticClass: "color-black" }, [
                          _vm._v(
                            "拍品数：" + _vm._s(row.distributePaiPinCount || 0)
                          )
                        ])
                      ]
                    )
                  ])
                }
              },
              {
                key: "itemAuctionOrOnLineSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.handleGoToUserAuctionList(row)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "color-blue cur-pot" }, [
                          _vm._v(
                            "拍品在架：" + _vm._s(row.paipinOnSelfNum || 0)
                          )
                        ]),
                        _c("div", { staticClass: "color-black" }, [
                          _vm._v("拍品总数：" + _vm._s(row.paipinTotalNum || 0))
                        ])
                      ]
                    )
                  ])
                }
              },
              {
                key: "itemFixedOrOnLineSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "div",
                      {
                        on: {
                          click: function($event) {
                            return _vm.handleGoToUserFixedList(row)
                          }
                        }
                      },
                      [
                        _c("div", { staticClass: "color-blue cur-pot" }, [
                          _vm._v(
                            "一口价在架：" + _vm._s(row.yikoujiaOnSelfNum || 0)
                          )
                        ]),
                        _c("div", { staticClass: "color-black" }, [
                          _vm._v(
                            "一口价总数：" + _vm._s(row.yikoujiaTotalNum || 0)
                          )
                        ])
                      ]
                    )
                  ])
                }
              },
              {
                key: "auctionBondPriceSlot",
                fn: function(row) {
                  return _c("div", {}, [
                    _c(
                      "span",
                      {
                        class:
                          row.sellerDepositMoney >= row.minDepositMoney
                            ? "color-green"
                            : "color-red"
                      },
                      [_vm._v(" " + _vm._s(row.sellerDepositMoney || 0) + " ")]
                    ),
                    _vm._v("/ "),
                    _c("span", [_vm._v(_vm._s(row.minDepositMoney))])
                  ])
                }
              },
              {
                key: "shopStarLevelSlot",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("a-rate", {
                        attrs: { disabled: "", count: 6 },
                        model: {
                          value: row.star,
                          callback: function($$v) {
                            _vm.$set(row, "star", $$v)
                          },
                          expression: "row.star"
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "setup",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      row.reviewStatus == 0 && row.payStatus == 1
                        ? _c(
                            "a-button",
                            {
                              attrs: { size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.reviewClick(row)
                                }
                              }
                            },
                            [_vm._v("审核")]
                          )
                        : _vm._e(),
                      row.payStatus
                        ? _c(
                            "div",
                            { staticStyle: { "margin-top": "10px" } },
                            [
                              _c(
                                "a-dropdown",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "overlay",
                                        fn: function() {
                                          return [
                                            _c(
                                              "a-menu",
                                              [
                                                _c(
                                                  "a-menu-item",
                                                  [
                                                    _c(
                                                      "a-popconfirm",
                                                      {
                                                        attrs: {
                                                          title:
                                                            "确定退部分开店费吗？（部分开店费根据用户使用时间来退）",
                                                          "ok-text": "Yes",
                                                          "cancel-text": "No"
                                                        },
                                                        on: {
                                                          confirm: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleShowShopBaseInfo(
                                                              row,
                                                              "returnShop",
                                                              "month"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              size: "small",
                                                              type: "primary"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "按天退开店费"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "a-menu-item",
                                                  [
                                                    _c(
                                                      "a-popconfirm",
                                                      {
                                                        attrs: {
                                                          title:
                                                            "确定退全额开店费吗？",
                                                          "ok-text": "Yes",
                                                          "cancel-text": "No"
                                                        },
                                                        on: {
                                                          confirm: function(
                                                            $event
                                                          ) {
                                                            return _vm.handleShowShopBaseInfo(
                                                              row,
                                                              "returnShop",
                                                              "all"
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a-button",
                                                          {
                                                            attrs: {
                                                              size: "small",
                                                              type: "primary"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "全额退开店费"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ],
                                                  1
                                                ),
                                                row.payMoney && false
                                                  ? _c(
                                                      "a-menu-item",
                                                      [
                                                        _c(
                                                          "a-popconfirm",
                                                          {
                                                            attrs: {
                                                              title:
                                                                "确定退全额开店费吗？",
                                                              "ok-text": "Yes",
                                                              "cancel-text":
                                                                "No"
                                                            },
                                                            on: {
                                                              confirm: function(
                                                                $event
                                                              ) {
                                                                return _vm.handleShowShopBaseInfo(
                                                                  row,
                                                                  "returnShop",
                                                                  "all"
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "a-button",
                                                              {
                                                                attrs: {
                                                                  size: "small",
                                                                  type:
                                                                    "primary"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "退500开电费"
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: { size: "small", type: "danger" }
                                    },
                                    [_vm._v("退开店费")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.handleShowShopBaseInfo(row, "line")
                                }
                              }
                            },
                            [_vm._v("店铺明细")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticStyle: { "margin-top": "10px" } },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "danger", size: "small" },
                              on: {
                                click: function($event) {
                                  return _vm.handleShowShopBaseInfo(
                                    row,
                                    "penalize"
                                  )
                                }
                              }
                            },
                            [_vm._v("惩罚")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "payBondPriceSlot",
                fn: function(row) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            row.minDepositMoney
                              ? row.minDepositMoney + "元"
                              : "无"
                          )
                        )
                      ]),
                      _c("a-button", {
                        staticClass: "ml-5",
                        attrs: { icon: "edit", size: "small" },
                        on: {
                          click: function($event) {
                            return _vm.handleChangeBondPrice(row)
                          }
                        }
                      })
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: { centered: "", width: 500 },
          on: { ok: _vm.checkForms, cancel: _vm.cancel },
          model: {
            value: _vm.showModal,
            callback: function($$v) {
              _vm.showModal = $$v
            },
            expression: "showModal"
          }
        },
        [
          _c(
            "div",
            { staticStyle: { height: "100px", "overflow-y": "auto" } },
            [
              _c(
                "a-form-model",
                _vm._b(
                  {
                    ref: "ruleForm",
                    attrs: { model: _vm.forms, rules: _vm.rules }
                  },
                  "a-form-model",
                  _vm.layout,
                  false
                ),
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "用户id", prop: "userId" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入用户ID" },
                        model: {
                          value: _vm.forms.userId,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "userId", $$v)
                          },
                          expression: "forms.userId"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    { attrs: { label: "店铺名称", prop: "shopName" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "请输入店铺名称" },
                        model: {
                          value: _vm.forms.shopName,
                          callback: function($$v) {
                            _vm.$set(_vm.forms, "shopName", $$v)
                          },
                          expression: "forms.shopName"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c("ShopBaseInfoPopup", {
        ref: "shopBaseInfoPopupEl",
        on: {
          setStar: function($event) {
            return _vm.handleSetSuccessStar($event)
          },
          setShopTagText: function($event) {
            return _vm.handleSetShopTagText($event)
          },
          changeEdit: function($event) {
            return _vm.handleChangeEdit($event)
          },
          updateUserCardInfoSuccess: function($event) {
            return _vm.handleUpdateUserCardInfoSuccess($event)
          }
        }
      }),
      _c("ShopChangeBondPricePopup", {
        ref: "shopChangeBondPricePopupEl",
        on: { successEdit: _vm.getData }
      }),
      _c("ShopPenalizeEditor", {
        ref: "shopPenalizeEditorEl",
        on: {
          blackSuccess: function($event) {
            return _vm.getData(_vm.params)
          }
        }
      }),
      _c("ShopPriceLinePopup", { ref: "shopPriceLinePopupEl" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }