import instance from "@/axios"

/** 获取物流信息 */
export const postUpdateUserCardInfo = async (params) => {
    return instance({
        url: '/dq_admin/userShopInfo/modifyLianLianUserInfo',
        method: 'post',
        data: params
    })
}
/** 获取相同用户的店铺 */
export const getSameShopByUserId = async (params) => {
    return instance({
        url: '/dq_admin/userShopInfo/sameIdentityCardNoShopList',
        method: 'get',
        params: params
    })
}